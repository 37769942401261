<template>
  <app-loading-container
    class="w-full px-4 py-6 mb-32 lg:py-16 sm:px-10"
    :loading="loading"
    :loaded="loaded"
    :error-loading="errorLoading"
  >
    <div
      class="
        relative
        grid
        h-auto
        grid-cols-1
        py-8
        bg-white
        gap-y-10
        lg:h-40
        sm:grid-cols-2
        lg:grid-cols-3
        rounded-xl
      "
    >
      <div class="pl-8 lg:border-r border-gray-type-11">
        <template v-if="loading">
          <div class="animate-pulse">
            <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
            <div class="mt-4 space-y-4">
              <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="font-bold uppercase text-xxs text-gray-type-8">
            Total fares
          </div>
          <div class="mt-6 text-2xl font-bold text-gray-type-2">
            {{ bookingMetricsFare || (bookingMetricsFare == 0) ? `₦ ${$filters.money(bookingMetricsFare)}` : 'N/A' }}
          </div>
        </template>
      </div>
      <div class="pl-8 lg:border-r border-gray-type-11">
        <template v-if="loading">
          <div class="animate-pulse">
            <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
            <div class="mt-4 space-y-4">
              <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="font-bold uppercase text-xxs text-gray-type-8">
            Total Trips
          </div>
          <div class="mt-6 text-2xl font-bold text-gray-type-2">
            {{ bookingMetricsCount }}
          </div>
        </template>
      </div>
      <div class="pl-8">
        <template v-if="loading">
          <div class="animate-pulse">
            <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
            <div class="mt-4 space-y-4">
              <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="font-bold uppercase text-xxs text-gray-type-8">
            Unique users
          </div>
          <div class="mt-6 text-2xl font-bold text-gray-type-2">
            {{ metrics.totalStaff }}
          </div>
        </template>
      </div>
      <div class="absolute right-0 mt-2 mr-4 lg:mt-8 sm:mr-8">
        <dropdown
          v-if="!loading"
          placement="bottom"
          class="
            flex
            items-center
            space-x-2
            text-xs
            font-medium
            text-gray-type-2
          "
          :auto-close="false"
        >
          <div>This month</div>
          <svg width="14" height="8" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 1l6 6 6-6"
              stroke="#131615"
              stroke-width="1.5"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <template v-slot:dropdown>
            <div
              class="w-32 mt-3 text-left bg-white rounded-lg"
              style="box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09)"
            >
              <button
                class="w-full px-4 py-3 font-medium hover:bg-gray-type-10"
              >
                This week
              </button>
              <button class="w-full px-4 py-3 font-medium bg-gray-type-10">
                This month
              </button>
              <button
                class="w-full px-4 py-3 font-medium hover:bg-gray-type-10"
                @click="showCustomDateModal = true"
              >
                Custom
              </button>
            </div>
          </template>
        </dropdown>
      </div>
    </div>
    <div class="grid w-full gap-8 mt-8 sm:grid-cols-3">
      <div class="px-8 py-6 bg-white rounded-xl">
        <template v-if="loading">
          <div class="animate-pulse">
            <div class="flex items-center justify-between">
              <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
              <div class="w-12 h-4 bg-gray-400 rounded"></div>
            </div>
            <div class="flex justify-between mt-6">
              <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
              <div class="w-12 h-4 bg-gray-400 rounded"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex items-center justify-between">
            <div class="font-bold uppercase text-xxs text-gray-type-8">
              Currently moving
            </div>
            <router-link
              :to="{ name: 'dashboard.buses' }"
              class="font-bold uppercase text-xxs text-green-type-1"
            >
              See BUSES
            </router-link>
          </div>
          <div class="flex justify-between">
            <div class="mt-6 text-2xl font-bold text-gray-type-2">
              {{ metrics.totalCurrentlyMoving }}
            </div>
            <div class="ml-auto text-right"></div>
          </div>
        </template>
      </div>
      <div class="px-8 py-6 bg-white rounded-xl">
        <template v-if="loading">
          <div class="animate-pulse">
            <div class="flex items-center justify-between">
              <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
              <div class="w-12 h-4 bg-gray-400 rounded"></div>
            </div>
            <div class="flex justify-between mt-6">
              <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
              <div class="w-12 h-4 bg-gray-400 rounded"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex items-center justify-between">
            <div class="font-bold uppercase text-xxs text-gray-type-8">
              Completed today
            </div>
          </div>
          <div class="flex justify-between">
            <div class="mt-6 text-2xl font-bold text-gray-type-2">
              {{ metrics.totalCompletedToday }}
            </div>
            <div class="self-end ml-auto text-right">
              <svg
                viewBox="0 0 32 32"
                class="w-12 h-12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fill-rule="evenodd">
                  <g fill="#C7D1CC" fill-rule="nonzero">
                    <path
                      d="M9.62 15.129v-1.944a.324.324 0 000-.648c-.893 0-1.62.727-1.62 1.62v.648c0 .536.436.972.972.972h.648a.324.324 0 000-.648zM21.934 12.537a.324.324 0 000 .648v1.944a.324.324 0 000 .648h.648a.973.973 0 00.972-.972v-.648c0-.893-.726-1.62-1.62-1.62zM13.509 21.61a.324.324 0 00-.324.324H11.24a.324.324 0 00-.648 0v.648c0 .536.436.972.972.972h1.297a.973.973 0 00.972-.972v-.648a.324.324 0 00-.324-.324zM20.638 21.61a.324.324 0 00-.324.324h-1.945a.324.324 0 00-.648 0v.648c0 .536.436.972.972.972h1.297a.973.973 0 00.972-.972v-.648a.324.324 0 00-.324-.324z"
                    />
                    <path
                      d="M20.638 8h-9.722c-.893 0-1.62.727-1.62 1.62v11.018c0 .893.727 1.62 1.62 1.62h9.722c.893 0 1.62-.727 1.62-1.62V9.62c0-.893-.727-1.62-1.62-1.62zm-8.425 1.296h7.129a.973.973 0 010 1.944h-7.13a.973.973 0 010-1.944zm0 11.018a.973.973 0 010-1.945.973.973 0 010 1.945zm7.129 0a.973.973 0 010-1.945.973.973 0 010 1.945zm1.62-4.213a.973.973 0 01-.972.972h-8.426a.973.973 0 01-.972-.972v-3.24c0-.536.437-.972.972-.972h8.426c.536 0 .972.436.972.972v3.24z"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </template>
      </div>
      <div class="px-8 py-6 bg-white rounded-xl">
        <template v-if="loading">
          <div class="animate-pulse">
            <div class="flex items-center justify-between">
              <div class="w-4/6 h-4 bg-gray-400 rounded"></div>
              <div class="w-12 h-4 bg-gray-400 rounded"></div>
            </div>
            <div class="flex justify-between mt-6">
              <div class="w-2/6 h-4 bg-gray-400 rounded"></div>
              <div class="w-12 h-4 bg-gray-400 rounded"></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex items-center justify-between">
            <div class="font-bold uppercase text-xxs text-gray-type-8">
              Today’s fares
            </div>
          </div>
          <div class="flex justify-between">
            <div class="mt-6 text-2xl font-bold text-gray-type-2">
              {{ metrics.todaysFare || (metrics.todaysFare == 0) ? `₦ ${$filters.money(metrics.todaysFare)}`: 'N/A' }}
            </div>
            <div class="self-end ml-auto text-right">
              <svg
                viewBox="0 0 48 48"
                class="w-12 h-12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fill-rule="evenodd">
                  <path fill="none" d="M0 0h48v48H0z" />
                  <g transform="translate(11 8)" fill-rule="nonzero">
                    <path fill="#C7D1CC" d="M12.013 16h1.3v6.807h-1.3z" />
                    <path
                      d="M6.373 16.527H8.34a3.847 3.847 0 013.847 3.846v2.434H9.76a3.847 3.847 0 01-3.847-3.847v-1.967c0-.255.205-.463.46-.466z"
                      fill="#C7D1CC"
                    />
                    <path
                      d="M9.467 19.64A26.047 26.047 0 006 16.713a.467.467 0 00-.107.28v1.967a3.847 3.847 0 003.854 3.847h2.433a26.8 26.8 0 00-2.713-3.167z"
                      fill="#ABBAB5"
                    />
                    <path
                      d="M15.573 22.807H13.14v-2.434a3.847 3.847 0 013.847-3.846h1.966c.258 0 .467.209.467.466v1.967a3.847 3.847 0 01-3.847 3.847z"
                      fill="#C7D1CC"
                    />
                    <path
                      d="M15.867 19.64a26.047 26.047 0 013.466-2.927c.066.08.103.178.107.28v1.967a3.847 3.847 0 01-3.853 3.847H13.14a26.8 26.8 0 012.727-3.167z"
                      fill="#ABBAB5"
                    />
                    <rect
                      fill="#ABBAB5"
                      x="6.187"
                      y="22.793"
                      width="12.967"
                      height="2.64"
                      rx="1.02"
                    />
                    <path
                      d="M15.333 32H10a1.06 1.06 0 01-1.007-.747l-1.74-5.82H18.08l-1.74 5.82a1.06 1.06 0 01-1.007.747z"
                      fill="#C7D1CC"
                    />
                    <rect
                      fill="#ABBAB5"
                      x=".393"
                      width="24.547"
                      height="14.92"
                      rx="2.44"
                    />
                    <circle fill="#FFF" cx="12.667" cy="7.46" r="4.373" />
                    <circle fill="#FFF" cx="3.44" cy="11.853" r="1" />
                    <circle fill="#FFF" cx="21.887" cy="3.067" r="1" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!--    <div class="grid w-full gap-8 mt-8 sm:grid-cols- bookings-tab">-->
    <!--        <div class="px-8 py-6 w-1/2 bg-white rounded-xl booking_">-->
    <!--          <div class="row_">-->
    <!--          <div class="font-bold uppercase text-xxs text-gray-type-8">-->
    <!--            Total bookings this month-->
    <!--          </div>-->
    <!--          &lt;!&ndash; <div class="font-bold uppercase text-xs text-gray-type-8 filter_">-->
    <!--            Filter:-->
    <!--            <select class="select_">-->
    <!--              <option>Jan</option>-->
    <!--              <option>Feb</option>-->
    <!--              <option>Mar</option>-->
    <!--              <option>April</option>-->
    <!--              <option>May</option>-->
    <!--              <option>June</option>-->
    <!--              <option>July</option>-->
    <!--              <option>Aug</option>-->
    <!--              <option>Sep</option>-->
    <!--              <option>Oct</option>-->
    <!--              <option>today</option>-->
    <!--              <option>today</option>-->
    <!--            </select>-->
    <!--          </div> &ndash;&gt;-->
    <!--          </div>-->
    <!--          <div class="mt-6 text-2xl font-bold text-gray-type-2">-->
    <!--            {{bookingMetricsCount}}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div class="px-8 py-6 w-1/2 bg-white rounded-xl booking_">-->
    <!--          <div class="font-bold uppercase text-xxs text-gray-type-8">-->
    <!--            Total booking fare this month-->
    <!--          </div>-->
    <!--          <div class="mt-6 text-2xl font-bold text-gray-type-2">-->
    <!--            ₦ {{ $filters.money(bookingMetricsFare) }}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--    </div>-->
    <div class="mt-10">
      <template v-if="loading">
        <div class="animate-pulse">
          <div class="w-40 h-4 ml-8 bg-gray-400 rounded"></div>
        </div>
      </template>
      <template v-else>
        <div class="ml-8 font-bold text-gray-type-2">Today’s trips</div>
      </template>
      <app-table
        :loading="loadingTodayTrips"
        :error-loading="errorLoadingTodayTrips"
        :items="trips"
        :fields="tripsFields"
        class="mt-6"
      ></app-table>
    </div>
    <slideover :show="showItemInformation">
      <div class="flex items-center justify-between px-6 mt-12">
        <button
          aria-label="Close panel"
          class="transition duration-150 ease-in-out text-gray-type-2"
          @click="showItemInformation = false"
        >
          <svg
            class="w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div class="mx-auto font-bold text-gray-type-2">Currently moving</div>
      </div>
      <div class="w-full mt-8 bg-gray-50" style="height: 412px"></div>
      <div class="px-6 mt-6">
        <div
          class="
            flex
            items-center
            justify-between
            w-full
            p-3
            rounded-full
            bg-gray-type-2
          "
        >
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 text-white rounded-full bg-green-type-1"></div>
            <div class="mt-1 text-xs font-bold text-white">
              0 passengers onboard
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 text-white rounded-full bg-green-type-1"></div>
            <div class="mt-1 text-xs font-bold text-white">
              0 passengers onboard
            </div>
          </div>
        </div>
        <div
          class="
            flex
            items-center
            justify-between
            w-full
            p-3
            mt-4
            rounded-full
            bg-gray-type-5
          "
        >
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 text-white rounded-full bg-green-type-1"></div>
            <div class="mt-1 text-xs font-bold text-gray-type-2">
              0 passengers onboard
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 text-white rounded-full bg-green-type-1"></div>
            <div class="mt-1 text-xs font-bold text-gray-type-2">
              0 passengers onboard
            </div>
          </div>
        </div>
        <div
          class="
            flex
            items-center
            justify-between
            w-full
            p-3
            mt-4
            rounded-full
            bg-gray-type-5
          "
        >
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 text-white rounded-full bg-green-type-1"></div>
            <div class="mt-1 text-xs font-bold text-gray-type-2">
              0 passengers onboard
            </div>
          </div>
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 text-white rounded-full bg-green-type-1"></div>
            <div class="mt-1 text-xs font-bold text-gray-type-2">
              0 passengers onboard
            </div>
          </div>
        </div>
      </div>
    </slideover>
    <modal :show="showCustomDateModal">
      <div class="p-6">
        <div class="flex items-center justify-between mt-4">
          <button
            aria-label="Close panel"
            class="transition duration-150 ease-in-out text-gray-type-2"
            @click="showCustomDateModal = false"
          >
            <svg
              class="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div class="mx-auto font-bold text-gray-type-2">
            Choose custom period
          </div>
        </div>
        <div class="mt-12">
          <div class="">
            <label class="text-xs font-medium text-gray-type-3" for="start-date"
              >Select start date</label
            >
            <div class="mt-2">
              <input
                type="date"
                id="start-date"
                class="
                  w-full
                  h-12
                  px-3
                  py-4
                  text-xs
                  font-medium
                  outline-none
                  placeholder-gray-type-3
                  bg-gray-type-4
                  focus:outline-none
                "
              />
            </div>
          </div>
          <div class="mt-6">
            <label class="text-xs font-medium text-gray-type-3" for="end-date"
              >Select end date</label
            >
            <div class="mt-2">
              <input
                type="date"
                id="end-date"
                class="
                  w-full
                  h-12
                  px-3
                  py-4
                  text-xs
                  font-medium
                  outline-none
                  placeholder-gray-type-3
                  bg-gray-type-4
                  focus:outline-none
                "
              />
            </div>
          </div>
          <button
            class="
              block
              w-full
              h-12
              py-4
              mt-12
              font-bold
              text-center text-white
              rounded
              bg-green-type-1
            "
            @click="showCustomDateModal = false"
          >
            Apply Custom Period
          </button>
        </div>
      </div>
    </modal>
  </app-loading-container>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@/components/Modal";
import AppTable from "@/components/AppTable";
import Dropdown from "@/components/Dropdown";
import Slideover from "@/components/Slideover";

export default {
  components: {
    Modal,
    AppTable,
    Dropdown,
    Slideover,
  },
  data() {
    let vm = this;
    return {
      io: null,
      loadingTodayTrips: false,
      errorLoadingTodayTrips: false,
      showItemInformation: false,
      showCustomDateModal: false,
      loading: false,
      loaded: false,
      errorLoading: false,
      metrics: null,
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      },
      markers: [],
      trips: [],
      bookingMetricsCount: null,
      bookingMetricsFare: 0,
      tripsFields: [
        { key: "user.fname,user.lname", label: "User" },
        { key: "userRoute.pickup.location", label: "From" },
        { key: "userRoute.destination.location", label: "To" },
        { key: "route.pickup", label: "Route" },
        {
          key: "userRoute.destination.fare",
          label: "Fare",
          filter: (v) => {
            return `₦ ${vm.$filters.money(v)}`;
          },
        },
      ],
    };
  },
  mounted() {
    this.emitter.on("close-slideover", () => {
      this.showItemInformation = false;
    });

    this.emitter.on("retry", () => {
      this.init();
    });
  },
  created() {
    this.init();
  },
  computed: {
    token() {
      if (!this.user) return null;
      return this.user.token.token;
    },
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    init() {
      this.loading = true;

      this.errorLoading = this.loaded = false;

      this.$http
        .get(`/corporates/${this.user.id}/metrics`)
        .then((res) => {
          this.metrics = res.data;
          this.loaded = true;
        })
        .catch(() => {
          this.errorLoading = true;
        })
        .finally(() => {
          this.loading = false;
        });

      this.loadTodaysTrip();
      this.getBookingMetrics();
    },
    loadTodaysTrip() {
      this.loadingTodayTrips = true;
      this.errorLoadingTodayTrips = false;
      this.$http
        .get(`/corporates/${this.user.id}/trips`)
        .then((res) => {
          this.trips = res.data;
        })
        .catch(() => {
          this.errorLoadingTodayTrips = true;
        })
        .finally(() => {
          this.loadingTodayTrips = false;
        });
    },
    getBookingMetrics() {
      this.$http
        .get(`corporates/${this.user.id}/booking-metrics/duration`)
        .then((res) => {
          this.bookingMetricsCount = res.data[0]["counts"];
          this.bookingMetricsFare = res.data[0]["total_amount"];
        })
        .catch((err) => {
          console.log(err);
          this.errorLoadingTodayTrips = true;
        })
        .finally(() => {
          this.loadingTodayTrips = false;
        });
    },
    showItem() {
      this.showItemInformation = !this.showItemInformation;
    },
  },
};
</script>
<style scoped>
.booking_ {
  margin-top: 5%;
  width: 100%;
  height: 120px;
  margin-right: 4%;
}

.row_ {
  display: flex;
  flex-flow: row;
}

.filter_ {
  margin-left: 20%;
}

.select_ {
  outline: none;
}
@media (min-width: 768px) {
  .bookings-tab {
    display: flex;
    flex-flow: row;
  }
}
</style>